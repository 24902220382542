import type {
  Professional,
  ProfessionalListResponse,
  ProfessionalResponse,
} from '~/types/__generated__'
import type { Strapi4RequestParams } from '~/types/strapi'

const FULL_PROFESSIONAL_POPULATE = [
  'address',
  'maximumRadiusKm',
  'about',
  'about.hobbies',
  'about.media',
  'experiences',
  'interactions',
  'interactions.company',
  'interactions.professional',
  'candidate',
  'candidate.assessments',
  'candidate.assessments.tasks',
  'candidate.assessments.providesResultsFor',
  'candidate.assessmentResults',
  'candidate.assessmentResults.data',
  'candidate.assessmentResults.data.subdata',
  'candidate.assessmentResults.type',
  'candidate.assessmentResults.assessmentReports',
  'candidate.assessmentResults.assessmentReports.files',
  'filterTags',
  'filterTags.tag',
  'tags',
  'tags.parent',
  'tags.children',
  'tags.parent.parent',
  'interests',
  'user',
  'user.contact',
  'user.contact.image',
  'user.contact.socials',
]

const OVERVIEW_PROFESSIONAL_POPULATE = [
  'user',
  'user.contact',
  'user.contact.image',
  'interactions',
  'interactions.company',
  'interactions.professional',
]

export function useApiProfessional() {
  const client = useStrapiClient()

  async function getProfessional(
    id: number | string,
    query: Strapi4RequestParams = {},
  ) {
    const { data } = await client<ProfessionalResponse>(
      `/professionals/${id}`,
      {
        method: 'GET',
        params: {
          populate: FULL_PROFESSIONAL_POPULATE,
          ...query,
        },
      },
    )

    return data
  }

  async function getProfessionals(query: Strapi4RequestParams = {}) {
    const response = await client<ProfessionalListResponse>(`/professionals`, {
      method: 'GET',
      params: {
        sort: ['match:desc'],
        populate: OVERVIEW_PROFESSIONAL_POPULATE,
        ...query,
      },
    })
    return response
  }

  async function updateProfessional(
    id: number | string,
    body: Partial<Professional>,
    query: Strapi4RequestParams = {},
  ) {
    const { data } = await client<ProfessionalResponse>(
      `/professionals/${id}`,
      {
        method: 'PUT',
        body,
        params: {
          populate: FULL_PROFESSIONAL_POPULATE,
          ...query,
        },
      },
    )
    return data
  }

  return {
    getProfessional,
    getProfessionals,
    updateProfessional,
    FULL_PROFESSIONAL_POPULATE,
  }
}
